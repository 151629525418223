import store from '@/store'
import {
  mdiAccountCheckOutline,
  mdiAccountOutline,
  mdiAccountPlusOutline,
  mdiAccountRemoveOutline,
  mdiCogOutline,
  mdiDatabaseOutline,
  mdiDnsOutline,
  mdiPencilOutline
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default function useUsersList() {
  const dataList = ref([])

  const tableColumns = [
    { text: '企业', value: 'enterprise_name' },
    { text: '模型', value: 'selected_model' },

    // { text: '开关', value: 'model_limit_switch' },
    {
      text: '操作',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const enterpriseId = ref(null)
  const totalUserListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['enterprise_name'],
    sortDesc: [true],
  })
  const selectedRows = ref([])

  // fetch data
  const fetchDatas = () => {
    store
      .dispatch('model-man/fetchDatas', {
      })
      .then(response => {
        console.log('3322', response)
        console.log('3322', enterpriseId)
        const { data } = response.data

        dataList.value = data.filter(item => (!enterpriseId.value || String(enterpriseId.value) === String(item.enterprise_id)))
        console.log('111133', dataList)
        totalUserListTable.value = dataList.length

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  const delData = id => {
    store.dispatch('model-man/delData', {
      enterpriseId: id,
    }).then(response => {
      fetchDatas()
    })
  }

  watch([searchQuery, enterpriseId, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchDatas()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'error'

    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return mdiAccountOutline
    if (role === 'author') return mdiCogOutline
    if (role === 'maintainer') return mdiDatabaseOutline
    if (role === 'editor') return mdiPencilOutline
    if (role === 'admin') return mdiDnsOutline

    return mdiAccountOutline
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'

    return 'primary'
  }

  const resolveUserTotalIcon = total => {
    if (total === 'Total Users') return { icon: mdiAccountOutline, color: 'primary' }
    if (total === 'Paid Users') return { icon: mdiAccountPlusOutline, color: 'error' }
    if (total === 'Active Users') return { icon: mdiAccountCheckOutline, color: 'success' }
    if (total === 'Pending Users') return { icon: mdiAccountRemoveOutline, color: 'warning' }

    return { icon: mdiAccountOutline, color: 'primary' }
  }

  return {
    dataList,
    tableColumns,
    searchQuery,
    enterpriseId,
    totalUserListTable,
    loading,
    options,
    selectedRows,
    fetchDatas,
    delData,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolveUserTotalIcon,
  }
}
