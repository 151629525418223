var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"user-list"}},[_c('user-list-add-new',{attrs:{"enterprise-id":_vm.editEnterpriseId,"enterprise-list":_vm.enterpriseList,"model-list":_vm.modelList,"data-list":_vm.dataList},on:{"refetch-data":_vm.fetchDatas},model:{value:(_vm.isAddNewUserSidebarActive),callback:function ($$v) {_vm.isAddNewUserSidebarActive=$$v},expression:"isAddNewUserSidebarActive"}}),_c('v-card',[_c('v-card-title',[_vm._v(" 企业模型管理 ")]),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('v-select',{attrs:{"loading":_vm.enterpriseListLoading,"placeholder":"选择企业","items":_vm.enterpriseList,"item-text":"name","item-value":"id","outlined":"","clearable":"","dense":"","hide-details":""},model:{value:(_vm.enterpriseId),callback:function ($$v) {_vm.enterpriseId=$$v},expression:"enterpriseId"}}),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.openAddDia('')}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("添加企业模型")])],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.dataList,"options":_vm.options,"disable-pagination":true,"hide-default-footer":true,"server-items-length":_vm.totalUserListTable,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.enterprise_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex enterprise_name-center"},[_vm._v(" "+_vm._s(item.enterprise_name)+"("+_vm._s(item.enterprise_id)+") ")])]}},{key:"item.selected_model",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-chip-group',{attrs:{"active-class":"primary--text","column":""}},_vm._l((item.selected_model),function(n){return _c('v-chip',{key:n.uid,attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(n.name)+" ")])}),1)],1)]}},{key:"item.model_limit_switch",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.model_limit_switch)+" "),_c('v-switch',{staticClass:"mb-4",attrs:{"hide-details":"","true-value":1,"false-value":0},on:{"change":function($event){return _vm.changeModelLimitSwitch(item)}},model:{value:(item.model_limit_switch),callback:function ($$v) {_vm.$set(item, "model_limit_switch", $$v)},expression:"item.model_limit_switch"}})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{on:{"click":function($event){return _vm.openAddDia(item.enterprise_id)}}},[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocumentOutline)+" ")]),_c('span',[_vm._v("编辑")])],1)],1),_c('v-list-item',[_c('v-list-item-title',{on:{"click":function($event){return _vm.delData(item.enterprise_id)}}},[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_c('span',[_vm._v("刪除")])],1)],1)],1)],1)]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }