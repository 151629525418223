<template>
  <v-navigation-drawer
    :value="isAddNewUserSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    @input="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <v-card
      height="100%"
      class="d-flex flex-column"
    >
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">企业模型管理</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-add-new-user-sidebar-active',false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text class="flex-grow-1 overflow-hidden">
        <v-form
          ref="form"
          class="d-flex flex-column"
          style="height:100%"
          @submit.prevent="onSubmit"
        >
          <!-- <v-switch
            v-model="userData.model_limit_switch"
            class="mb-4"
            label="开关"
            hide-details
            :true-value="1"
            :false-value="0"
          ></v-switch> -->
          <v-select
            v-model="userData.enterprise_id"
            :rules="[validators.required2]"
            :disabled="!!enterpriseId"
            :item-disabled="isDisabledItem"
            label="企业"
            :items="enterpriseList"
            item-text="name"
            item-value="id"
            outlined
            clearable
            dense
            hide-details="auto"
            class="flex-grow-0"
          ></v-select>
          <v-container
            fluid
            class="flex-grow-1 overflow-auto"
          >
            <v-checkbox
              v-for="item in fillModelList"
              :key="item.uid"
              v-model="userData.selected_model"
              :label="item.name"
              :value="item.uid"
            ></v-checkbox>
          </v-container>
          <div>
            <v-btn
              color="primary"
              class="me-3"
              type="submit"
            >
              确定
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              type="reset"
              @click="resetuserData"
            >
              取消
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { required } from '@core/utils/validation'
import { mdiClose } from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'

export default {
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    enterpriseId: {
      type: String,
      required: false,
      default: '',
    },
    enterpriseList: {
      type: Array,
      required: true,
    },
    modelList: {
      type: Array,
      required: true,
    },
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    dataList: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const blankUserData = {
      enterprise_id: '',
      enterprise_name: '',
      model_limit_switch: 1,
      selected_model: [],
    }

    const form = ref(null)

    const validate = () => form.value.validate()

    const resetForm = () => {
      form.value.reset()
    }

    const fillModelList = computed(() => {
      if (props.enterpriseId) {
        const data = props.dataList.find(item => item.enterprise_id === props.enterpriseId)
        const arr = [...props.modelList]
        data.selected_model.forEach(item => {
          if (!arr.find(o => o.uid === item.uid)) {
            arr.push(item)
          }
        })

        return arr
      }

      return props.modelList
    })

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    watch(() => props.isAddNewUserSidebarActive, value => {
      if (value && props.enterpriseId) {
        // userData
        const data = props.dataList.find(item => item.enterprise_id === props.enterpriseId)
        console.log('333322', data)
        if (data) {
          userData.value = {
            enterprise_id: data.enterprise_id,
            enterprise_name: data.enterprise_name,
            model_limit_switch: data.model_limit_switch,
            selected_model: data.selected_model.map(item => item.uid),
          }
        }
      }
    })
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
      resetForm()
      emit('update:is-add-new-user-sidebar-active', false)
    }

    const onSubmit = () => {
      if (validate()) {
        const enterprise = props.enterpriseList.find(item => item.id === userData.value.enterprise_id)

        const params = {
          enterprise_id: userData.value.enterprise_id,
          enterprise_name: enterprise?.name || '',

          // model_limit_switch: userData.value.model_limit_switch,
          model_limit_switch: 1,
          selected_model: userData.value.selected_model.map(uid => {
            const model = fillModelList.value.find(o => (o.uid === uid))

            return {
              uid,
              name: model.name,
            }
          }),
        }
        console.log('11112', params)
        store.dispatch('model-man/addUser', params).then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })

        resetuserData()
      } else {
        validate()
      }
    }

    const isDisabledItem = ({ id }) => !!props.dataList.find(item => (item.enterprise_id === id))

    const required2 = item => !!item

    return {
      resetuserData,
      form,
      onSubmit,
      countries,
      userData,
      validate,

      // validation
      validators: { required, required2 },
      icons: {
        mdiClose,
      },
      isDisabledItem,
      fillModelList,
    }
  },
}
</script>
