import axios from '@axios'

export default {
  namespaced: true,
  state: {

  },
  getters: {},
  mutations: {},
  actions: {
    fetchDatas(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/llmApp/enterprise_model_config/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/user/users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/llmApp/enterprise_model_config/store', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    delData(ctx, { enterpriseId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/llmApp/enterprise_model_config/delete?enterprise_id=${enterpriseId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getEnterpriseList(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/botService/v1/tenants/', params)
          .then(response => {
            response.data.data.forEach(item => {
              // eslint-disable-next-line no-param-reassign
              item.id = String(item.id)
            })
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getModelList(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/llmApp/model/list/v2', {
            headers: {
              'X-User-Phone': '',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
