<template>
  <div id="user-list">
    <!-- app drawer -->
    <user-list-add-new
      v-model="isAddNewUserSidebarActive"
      :enterprise-id="editEnterpriseId"
      :enterprise-list="enterpriseList"
      :model-list="modelList"
      :data-list="dataList"
      @refetch-data="fetchDatas"
    ></user-list-add-new>
    <!-- @refetch-data="fetchUsers" -->

    <!-- list filters -->
    <v-card>
      <v-card-title>
        企业模型管理
      </v-card-title>
      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-select
          v-model="enterpriseId"
          :loading="enterpriseListLoading"
          placeholder="选择企业"
          :items="enterpriseList"
          item-text="name"
          item-value="id"
          outlined
          clearable
          dense
          hide-details
        ></v-select>
        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <!-- :disabled="!(enterpriseId && dataList.length === 0)" -->
          <v-btn
            color="primary"
            class="mb-4 me-3"
            @click.stop="openAddDia('')"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>添加企业模型</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="dataList"
        :options.sync="options"
        :disable-pagination="true"
        :hide-default-footer="true"
        :server-items-length="totalUserListTable"
        :loading="loading"
      >
        <template #[`item.enterprise_name`]="{item}">
          <div class="d-flex enterprise_name-center">
            {{ item.enterprise_name }}({{ item.enterprise_id }})
          </div>
        </template>
        <template #[`item.selected_model`]="{item}">
          <div class="d-flex align-center">
            <v-chip-group
              active-class="primary--text"
              column
            >
              <v-chip
                v-for="n in item.selected_model"
                :key="n.uid"
                color="primary"
              >
                {{ n.name }}
              </v-chip>
            </v-chip-group>
          </div>
        </template>
        <template #[`item.model_limit_switch`]="{item}">
          <div class="d-flex align-center">
            {{ item.model_limit_switch }}
            <v-switch
              v-model="item.model_limit_switch"
              class="mb-4"
              hide-details
              :true-value="1"
              :false-value="0"
              @change="changeModelLimitSwitch(item)"
            ></v-switch>
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item>
                <v-list-item-title @click="openAddDia(item.enterprise_id)">
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>编辑</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-title @click="delData(item.enterprise_id)">
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>刪除</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import {
  mdiAccountOutline, mdiDeleteOutline, mdiDotsVertical, mdiExportVariant, mdiFileDocumentOutline, mdiPlus, mdiSquareEditOutline,
} from '@mdi/js'
import { onUnmounted, ref } from '@vue/composition-api'

// sidebar
import { avatarText } from '@core/utils/filter'
import UserListAddNew from './modelListAddNew.vue'
import userStoreModule from './modelStoreModule'

import modelModelList from './modelModelList'

export default {
  components: {
    UserListAddNew,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'model-man'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    const isAddNewUserSidebarActive = ref(false)
    const editEnterpriseId = ref(null)
    const openAddDia = id => {
      console.log('333322', id)
      editEnterpriseId.value = id
      isAddNewUserSidebarActive.value = !isAddNewUserSidebarActive.value
    }

    const enterpriseListLoading = ref(true)
    const enterpriseList = ref([])
    store.dispatch('model-man/getEnterpriseList', {
    }).then(response => {
      enterpriseList.value = response.data.data
      enterpriseListLoading.value = false
    })
    const modelList = ref([])
    store.dispatch('model-man/getModelList', {
    }).then(response => {
      console.log('3333', response)

      modelList.value = response.data.data

      // {
      //   name: '',
      //   uid: ''
      // }
    })

    const {
      dataList,
      tableColumns,
      searchQuery,
      enterpriseId,
      totalUserListTable,
      loading,
      options,
      selectedRows,
      fetchDatas,
      delData,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserTotalIcon,
    } = modelModelList()

    const changeModelLimitSwitch = item => {
      const params = { ...item }
      console.log('3333', params)
      store.dispatch('model-man/addUser', params).then(() => {
      })
    }

    return {
      enterpriseList,
      enterpriseListLoading,
      dataList,
      tableColumns,
      searchQuery,
      enterpriseId,
      totalUserListTable,
      loading,
      options,
      isAddNewUserSidebarActive,
      selectedRows,
      avatarText,
      delData,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserTotalIcon,
      fetchDatas,
      openAddDia,
      editEnterpriseId,
      modelList,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
      },
      changeModelLimitSwitch,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
